import dynamic from 'next/dynamic';
import type { PartialContext } from '../../types/cms';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { Loader, LoaderOptions } from '../UI/Loader';
import RenderHTMLComment from './ReactComment';

const componentMap = {
  'article-index': dynamic(() => import('./article/article-index/article-index')),
  'group-grid-article': dynamic(() => import('./group/group-grid/group-grid-article/group-grid-article')),
  'group-grid-default': dynamic(() => import('./group/group-grid/group-grid-default/group-grid-default')),
  'group-grid-combi-ad': dynamic(() => import('./group/group-grid/group-grid-combi-ad/group-grid-combi-ad')),
  'group-grid-standard': dynamic(() => import('./group/group-grid/group-grid-auto-fill/group-grid-auto-fill')),
  'group-grid-fixed-sidebar': dynamic(
    () => import('./group/group-grid/group-grid-fixed-sidebar/group-grid-fixed-sidebar')
  ),
  'group-grid-persona': dynamic(() => import('./group/group-grid/group-grid-persona/group-grid-persona')),
  'group-preview-default': dynamic(() => import('./group/group-preview/group-preview-default')),
  'page-index': dynamic(() => import('./page/page-index/page-index')),
  'widget-adslot-default': dynamic(() => import('./widget/widget-adslot/widget-adslot-default')),
  'widget-article-detail-default': dynamic(
    () => import('./widget/widget-article-detail/widget-article-detail-default/widget-article-detail-default')
  ),
  'widget-article-detail-head': dynamic(
    () => import('./widget/widget-article-detail/widget-article-detail-head/widget-article-detail-head')
  ),
  'widget-article-detail-video': dynamic(
    () => import('./widget/widget-article-detail/widget-article-detail-video/widget-article-detail-video'),
    { loading: () => <Loader type={LoaderOptions.VideoWithRelated} /> }
  ),
  'widget-storyline_date_and_author-default': dynamic(
    () => import('./widget/widget-storyline_date_and_author/widget-storyline_date_and_author-default')
  ),
  'widget-storyline_affiliate-default': dynamic(
    () => import('./widget/widget-storyline_affiliate/widget-storyline_affiliate-default')
  ),
  'widget-topic_page_links-default': dynamic(
    () => import('./widget/widget-topic_page_links-default/widget-topic_page_links-default')
  ),
  'widget-iframe-default': dynamic(() => import('./widget/widget-iframe/widget-iframe-default')),
  'widget-integration-default': dynamic(() => import('./widget/widget-integration/widget-integration-default')),
  'widget-menu-default': dynamic(() => import('./widget/widget-menu/widget-menu-default')),
  'widget-menu-footer': dynamic(() => import('./widget/widget-menu/widget-menu-footer')),
  'widget-menu-header': dynamic(() => import('./widget/widget-menu/widget-menu-header')),
  'widget-menu-seobox': dynamic(() => import('./widget/widget-menu/widget-menu-seobox')),
  'widget-pagination-default': dynamic(() => import('./widget/widget-pagination/widget-pagination-default')),
  'widget-picture-full': dynamic(() => import('./widget/widget-picture/widget-picture-full')),
  'widget-picture-inline': dynamic(() => import('./widget/widget-picture/widget-picture-inline')),
  'widget-seolinks-default': dynamic(() => import('./widget/widget-seolinks/widget-seolinks-default')),
  'widget-series-default': dynamic(() => import('./widget/widget-series/widget-series-default')),
  'widget-series-menu': dynamic(() => import('./widget/widget-series/widget-series-menu')),
  'widget-teaser-aufmacher': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-aufmacher/widget-teaser-aufmacher')
  ),
  'widget-teaser-banner': dynamic(() => import('./widget/widget-teaser/widget-teaser-banner/widget-teaser-banner')),
  'widget-teaser-button': dynamic(() => import('./widget/widget-teaser/widget-teaser-button/widget-teaser-button')),
  'widget-teaser-news-ticker': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-news-ticker/widget-teaser-news-ticker')
  ),
  'widget-teaser-standard': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-standard/widget-teaser-standard')
  ),
  'widget-teaser-video': dynamic(() => import('./widget/widget-teaser/widget-teaser-video/widget-teaser-video')),
  'widget-teaser-playlist': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-playlist/widget-teaser-playlist')
  ),
  'widget-teaser-lesetipp': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-lesetipp/widget-teaser-lesetipp')
  ),
  'widget-teaser-news-check': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-news-check/widget-teaser-news-check')
  ),
  'widget-teaser-rtl-plus': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-rtl-plus/widget-teaser-rtl-plus')
  ),
  'widget-teaser-rtl-plus-ganze-folgen': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-rtl-plus-ganze-folgen/widget-teaser-rtl-plus-ganze-folgen')
  ),
  'widget-teaser-rtl-plus-letzte-folgen': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-rtl-plus-letzte-folgen/widget-teaser-rtl-plus-letzte-folgen')
  ),
  'widget-teaser-rtl-plus-top5': dynamic(
    () => import('./widget/widget-teaser/widget-teaser-rtl-plus-top5/widget-teaser-rtl-plus-top5')
  ),
  'widget-topics-default': dynamic(() => import('./widget/widget-topics/widget-topics-default')),
  'widget-topics-menu': dynamic(() => import('./widget/widget-topics/widget-topics-menu')),
  'widget-typography-default': dynamic(() => import('./widget/widget-typography/widget-typography-default')),
  'widget-video-detail': dynamic(() => import('./widget/widget-video/widget-video-detail/widget-video-detail')),
  'widget-autonative-default': dynamic(() => import('./widget/widget-autonative/widget-autonative-default')),
};

type DynamicComponent = keyof typeof componentMap;

const constructDynamicName = (type: string, template: string, view?: string) => {
  const dynamicName = [type, template, view]
    .filter((str) => str !== undefined)
    .join('-')
    .toLowerCase();
  return dynamicName as DynamicComponent;
};

const Partials = ({ context }: { context: PartialContext }) => {
  const { type, template, view } = context;
  const dynamicName = constructDynamicName(type, template, view);
  const Dynamic = componentMap[dynamicName];

  if (Dynamic) {
    return (
      <ErrorBoundary>
        <RenderHTMLComment widgetType={dynamicName} id={context?.id} />
        <Dynamic context={context} />
      </ErrorBoundary>
    );
  }

  return (
    <p>
      Cannot find <strong>{dynamicName}</strong>
    </p>
  );
};

export default Partials;
