import type { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { clsx } from 'clsx';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import type { PropsWithClassName } from '../../../types/react-props';
import { getRelProperty } from '../../../utils/getRelProperty';

export type LinkProps = PropsWithClassName<{
  href: string;
  children: ReactNode;
  target?: HTMLAttributeAnchorTarget;
  plainLink?: boolean;
  noFollow?: boolean;
  title?: string;
}>;

const Link = ({ children, href, target, plainLink = false, noFollow, className, title }: LinkProps) => {
  if (plainLink) {
    return (
      <a href={href} className={clsx(className)} rel={getRelProperty(noFollow, target)} target={target} title={title}>
        {children}
      </a>
    );
  }

  return (
    <NextLink
      prefetch={false}
      rel={getRelProperty(noFollow, target)}
      className={clsx(className)}
      href={href}
      target={target}
      title={title}
    >
      {children}
    </NextLink>
  );
};

export default Link;
