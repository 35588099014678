export const getRelProperty = (noFollow?: boolean, target?: string, sponsored?: boolean) => {
  let relProperty = '';
  if (target === '_blank') {
    relProperty += 'noopener noreferrer';
  }

  if (noFollow) {
    if (relProperty !== '') {
      relProperty += ' ';
    }
    relProperty += 'noFollow';
  }

  if (sponsored) {
    if (relProperty !== '') {
      relProperty += ' ';
    }
    relProperty += 'sponsored';
  }

  return relProperty !== '' ? relProperty : undefined;
};
