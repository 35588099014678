import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import styles from './Logo.module.scss';

export type LogoProps = PropsWithClassName<{
  readonly size?: 'small' | 'medium';
  readonly variant?: 'default' | 'rtlPlus';
}>;

const Logo = ({ size = 'medium', className, variant }: LogoProps) => {
  if (variant === 'rtlPlus') {
    return (
      <svg className={className} width="82" height="12" viewBox="0 0 82 12" role="img" name="RTL+ Logo">
        <title>RTL+</title>
        <path fill="#020203" d="M0 0h21.338v12H0z" />
        <path
          fill="#fff"
          d="M6.061 2.758h5.686c1.731 0 2.695.778 2.695 2.084 0 1.12-.723 1.871-1.954 2.047l2.788 2.353h-2.139l-2.621-2.27H7.544v2.269H6.062V2.758h-.001Zm5.492 3c.917 0 1.361-.296 1.361-.898 0-.603-.444-.89-1.361-.89H7.544v1.787h4.009v.001Z"
        />
        <path fill="#020203" d="M23.47 0h21.338v12H23.47z" />
        <path fill="#fff" d="M33.397 4.036h-3.75V2.758h8.983v1.278h-3.75v5.206h-1.483V4.036Z" />
        <path fill="#020203" d="M46.939 0h21.338v12H46.939z" />
        <path fill="#fff" d="M53.57 2.758h1.481v5.206h6.594v1.278H53.57V2.758Z" />
        <path
          fill="#020203"
          d="M81.081 5.233h-3.496V2.594h-1.546v2.639h-3.493v1.548h3.493v2.621h1.546V6.781h3.496V5.233Z"
        />
      </svg>
    );
  }

  return (
    <svg
      className={clsx(styles.logo, className, {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
      })}
      viewBox="0 0 160 28"
      focusable="false"
      role="img"
      name="RTL Logo"
    >
      <title>RTL</title>
      <path fill="#0036f2" d="M0 0h49.725v28H0z" />
      <path
        fill="#fff"
        d="M14.101 6.41h13.224c4.049 0 6.275 1.821 6.275 4.857 0 2.632-1.687 4.386-4.588 4.79l6.477 5.466h-4.993l-6.14-5.263H17.61v5.263h-3.441V6.41H14.1Zm12.82 7.017c2.159 0 3.17-.675 3.17-2.092s-1.011-2.092-3.17-2.092H17.61v4.184h9.31Z"
      />

      <path fill="#00acf2" d="M54.718 0h49.725v28H54.718z" />
      <path fill="#fff" d="M77.86 9.378h-8.77V6.41h20.982v2.968h-8.77v12.145H77.86V9.378Z" />

      <path fill="#fa002e" d="M109.436 0h49.725v28h-49.725z" />
      <path fill="#fff" d="M124.887 6.41h3.44v12.144h15.384v2.969h-18.824V6.41Z" />
    </svg>
  );
};

export default Logo;
